import React, { useEffect, useState, useCallback } from 'react'
import sectionService from '../services/section.service'
import LoadingSpinner from './loadingSpinner'
import { useDispatch } from 'react-redux'
import { enqueueSnackbar } from '../actions/notification.actions'
import { useSelector } from 'react-redux'

const OtherSectionContent = ({ otherSectionId }) => {
  const dispatch = useDispatch()
  const [sectionData, setSectionData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const getSectionContent = useCallback(async () => {
    try {
      setIsLoading(true)
      const sectionResponse = await sectionService.getOtherSectionById(
        otherSectionId
      )
      setSectionData(sectionResponse)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      dispatch(
        enqueueSnackbar({
          message: `Could not fetch content.`,
          options: {
            variant: 'error',
          },
        })
      )
    }
  }, [dispatch, otherSectionId])

  useEffect(() => {
    getSectionContent()
  }, [getSectionContent])


  if (isLoading || !otherSectionId) {
    return <LoadingSpinner text='Loading content' />
  }

  return (
    <>
      <h1>{sectionData?.name}</h1>
      <div dangerouslySetInnerHTML={{ __html: sectionData?.contentHtml }}></div>
    </>
  )
}

export default OtherSectionContent
