export default {
  KNOWLEDGE_PORTAL: 'KnowledgePortalUser',
  HR: 'HRUser',
  BUSINESS: 'BusinessUser',
  ADMIN: 'AdminUser',
  MI: 'MIUser',
}

export const displayNames = {
  KnowledgePortalUser: 'Knowledge Portal User',
  HRUser: 'HR User',
  BusinessUser: 'Business User',
  AdminUser: 'Admin User',
  MIUser: 'MI User',
}
