import React, { useEffect } from 'react'
import { signInSilentCallback } from '../services/auth.service'

const SignInSilentCallback = () => {
  useEffect(() => {
    const signInSilentCallbackAsync = async () => {
      await signInSilentCallback()
    }

    signInSilentCallbackAsync()
  }, [])
  return <div>Renewing token</div>
}

export default SignInSilentCallback
