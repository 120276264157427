import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getServiceApplications,
  getServiceBranding,
} from '../../actions/branding.actions'
import LoadingPage from '../loadingPage'

const ThemeLoader = ({ children }) => {
  const dispatch = useDispatch()
  const { isLoadingTheme, theme } = useSelector(state => state.branding)

  useEffect(() => {
    if (theme === null) {
      dispatch(getServiceBranding())
      dispatch(getServiceApplications())
    }
  }, [dispatch, theme])

  return isLoadingTheme ? <LoadingPage text='Loading theme' /> : children
}

export default ThemeLoader
