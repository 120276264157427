import httpClient from '../utils/axios'
import {
  handleServiceError,
  handleServiceResponse,
} from '../utils/serviceResponseHelper'

const getTopicAsync = async (queryString)=> {
  return httpClient
    .get(
      `${window.config.KNOWLEDGEPORTAL_API_URL}/v1/ContentManagement/Topic?${queryString}`
    )
    .then(response => {
      return handleServiceResponse(response)
    })
    .catch(error => handleServiceError(error))
}

const getSectionsAndTopicsAsync = async () => {
  return httpClient
    .get(
      `${window.config.KNOWLEDGEPORTAL_API_URL}/v1/ContentManagement/SectionTopicMenuItems`
    )
    .then(response => {
      return handleServiceResponse(response)
    })
    .catch(error => handleServiceError(error))
}

const sectionTopicSegmentTracking = async (queryString) => {
  return httpClient
    .post(
      `${window.config.KNOWLEDGEPORTAL_API_URL}/v1/ContentManagement/SectionTopicSegments?${queryString}`
    )
    .then(response => {
      return handleServiceResponse(response)
    })
    .catch(error => handleServiceError(error))
}

const getSegmentIdFromLegacyId = async legacyId => {
  return await httpClient
    .get(
      `${window.config.KNOWLEDGEPORTAL_API_URL}/v1/ContentManagement/SegmentId?legacyId=${legacyId}`
    )
    .then(response => {
      return handleServiceResponse(response)
    })
    .catch(error => handleServiceError(error))
}

const getTopicIdFromLegacyId = async legacyId => {
  return await httpClient
    .get(
      `${window.config.KNOWLEDGEPORTAL_API_URL}/v1/ContentManagement/TopicId?legacyId=${legacyId}`
    )
    .then(response => {
      return handleServiceResponse(response)
    })
    .catch(error => handleServiceError(error))
}

export default {
  getSectionsAndTopicsAsync,
  getTopicAsync,
  sectionTopicSegmentTracking,
  getSegmentIdFromLegacyId,
  getTopicIdFromLegacyId,
}
