import {
  USER_AVAILABILITY_REQUEST,
  USER_AVAILABILITY_SUCCESS,
  USER_AVAILABILITY_FAILURE,
} from '../actions/types'

import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  availability: null,
  isLoading: true,
  error: null,
}

export default createReducer(initialState, {
  [USER_AVAILABILITY_REQUEST]: state => {
    state.isLoading = true
  },
  [USER_AVAILABILITY_SUCCESS]: (state, action) => {
    state.availability = action.payload
    state.isLoading = false
    state.error = null
  },
  [USER_AVAILABILITY_FAILURE]: (state, action) => {
    state.availability = null
    state.isLoading = false
    state.error = action.payload
  },
})
