import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import MainLayout from '../layouts/mainLayout'
import { useSelector, useDispatch } from 'react-redux'
import { setIsSearching } from '../actions/search.actions'
import { useParams } from 'react-router-dom'
import LoadingSpinner from '../components/loadingSpinner'
import searchService from '../services/search.service'
import { Paper } from '@material-ui/core'
import DocumentLink from '../components/documentLink'

const Search = () => {
  const dispatch = useDispatch()
  const { isSearching } = useSelector(state => state.search)
  const { encodedUriSearchTerm } = useParams()

  const [searchResults, setSearchResults] = useState(null)
  const [searchTermIsValid, setSearchTermIsValid] = useState(false)
  const [minimumSearchLength] = useState(3)
  const [maximumSearchLength] = useState(100)
  const [searchTerm, setSearchTerm] = useState('')

  const checkValidity = useCallback(
    value => {
      return (
        value &&
        value.length >= minimumSearchLength &&
        value.length <= maximumSearchLength
      )
    },
    [minimumSearchLength, maximumSearchLength]
  )

  const runSearch = useCallback(
    value => {
      setSearchResults(null)
      if (value && checkValidity(value)) {
        dispatch(setIsSearching(true))
        searchService
          .searchContentAsync(encodeURIComponent(value))
          .then(result => {
            setSearchResults(result)
            dispatch(setIsSearching(false))
          })
      } else {
        setSearchTermIsValid(false)
      }
    },
    [checkValidity, dispatch]
  )

  useEffect(() => {
    setSearchTermIsValid(checkValidity(searchTerm))
  }, [searchTerm, checkValidity])

  //GET SEARCH TERM FROM URL, POPULATE THE PAGE AND RUN THE SEARCH
  useEffect(() => {
    if (encodedUriSearchTerm) {
      const decodedValue = decodeURIComponent(encodedUriSearchTerm)
      setSearchTerm(decodedValue)
      runSearch(decodedValue)
    }
  }, [encodedUriSearchTerm, runSearch])

  const BuildSectionDetails = document => {
    return {
      sectionId: document.sectionId,
      sectionName: document.sectionName,
      topicId: document.topicId,
      topicName: document.topicName,
    }
  }

  return (
    <MainLayout>
      <h1>Search</h1>
      {isSearching && <LoadingSpinner text={`Searching for ${searchTerm}`} />}
      {!isSearching && (
        <Paper style={{ padding: 30 }}>
          {!searchTermIsValid && <p>Search term is invalid</p>}
          {!isSearching && searchResults && (
            <h3>
              Search Results (
              {searchResults.topicSearchResults.length +
                searchResults.documentSearchResults.length +
                searchResults.segmentSearchResults.length}
              )
            </h3>
          )}
          {!isSearching &&
            searchResults &&
            searchResults.topicSearchResults.length > 0 && (
              <>
                <h4>Topics ({searchResults.topicSearchResults.length})</h4>
                {searchResults.topicSearchResults
                  .sort((a, b) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                  )
                  .map(topic => (
                    <p key={topic.name}>
                      <Link
                        style={{ color: '#2980b9' }}
                        to={{ pathname: `/topic/${topic.id}` }}>
                        {topic.name}
                      </Link>
                    </p>
                  ))}
              </>
            )}
          {!isSearching &&
            searchResults &&
            searchResults.segmentSearchResults.length > 0 && (
              <>
                <h4>Segments ({searchResults.segmentSearchResults.length})</h4>
                {searchResults.segmentSearchResults
                  .sort((a, b) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                  )
                  .map(segment => (
                    <p key={segment.id}>
                      <Link
                        style={{ color: '#2980b9' }}
                        to={`/topic/${segment.topicId}?segmentId=${segment.id}&segmentName=${segment.name}`}>
                        {segment.name}
                      </Link>
                    </p>
                  ))}
              </>
            )}
          {!isSearching &&
            searchResults &&
            searchResults.documentSearchResults.length > 0 && (
              <>
                <h4>
                  Documents ({searchResults.documentSearchResults.length})
                </h4>
                {searchResults.documentSearchResults
                  .sort((a, b) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                  )
                  .map(document => (
                    <p key={document.id}>
                      <DocumentLink
                        key={document.id}
                        documentId={document.documentId}
                        documentName={document.name}
                        fileName={document.filename}
                        showIcon={true}
                        sectionAndTopicDetails={BuildSectionDetails(document)}
                        documentLocation={document.fileNameLocation}
                      />
                    </p>
                  ))}
              </>
            )}
          {!isSearching &&
            searchResults &&
            searchResults.topicSearchResults.length +
              searchResults.documentSearchResults.length +
              searchResults.segmentSearchResults.length ===
              0 && <p>No results found</p>}
        </Paper>
      )}
    </MainLayout>
  )
}

export default Search
