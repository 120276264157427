import React from 'react'
import { useSelector } from 'react-redux'
import GnattaChat from './GnattaChat'
import * as helpers from '../../helpers/user'
import roleConstant from '../../constants/roles.constants'

export const GnattaChatComponent = () => {
  const userRoles = useSelector(state =>
    helpers.getUserRoles(state.auth.profile)
  )

  const service = useSelector(state => state.service)
  const isBusinessUser = userRoles.includes(roleConstant.BUSINESS)
  const isHrUser = userRoles.includes(roleConstant.HR)

  const {
    given_name: givenName,
    family_name: familyName,
    sub: userId,
  } = useSelector(state => state.auth.profile)

  const fullName = givenName + ' ' + familyName

  if (
    service !== null &&
    service.isWebChatEnabled &&
    service.webChatClientCode !== undefined &&
    givenName !== undefined &&
    (isBusinessUser || isHrUser)
  ) {
    return (
      <GnattaChat
        Name={fullName}
        UserId={userId}
        WebChatClientCode={service.webChatClientCode}
      />
    )
  }
  return null
}

export default GnattaChatComponent
