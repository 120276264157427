import httpClient from '../utils/axios'
import {
  handleServiceError,
  handleServiceResponse,
} from '../utils/serviceResponseHelper'

const getSectionsAndTopicsAsync = async () => {
  return httpClient
    .get(
      `${window.config.KNOWLEDGEPORTAL_API_URL}/v1/ContentManagement/SectionTopicMenuItems`
    )
    .then(response => {
      return handleServiceResponse(response)
    })
    .catch(error => handleServiceError(error))
}

const getOtherSectionById = otherSectionId => {
  return httpClient
    .get(
      `${window.config.KNOWLEDGEPORTAL_API_URL}/v1/ContentManagement/OtherSection/${otherSectionId}`
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

export default {
  getSectionsAndTopicsAsync,
  getOtherSectionById,
}
