import {
  SECTION_TOPIC_DATA_REQUEST,
  SECTION_TOPIC_DATA_SUCCESS,
  SECTION_TOPIC_DATA_FAILURE,
} from '../actions/types'

import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  sectionTopics: null,
  isLoading: true,
  error: null,
}

export default createReducer(initialState, {
  [SECTION_TOPIC_DATA_REQUEST]: state => {
    state.isLoading = true
  },
  [SECTION_TOPIC_DATA_SUCCESS]: (state, action) => {
    state.sectionTopics = action.payload
    state.isLoading = false
    state.error = null
  },
  [SECTION_TOPIC_DATA_FAILURE]: (state, action) => {
    state.sectionTopics = null
    state.isLoading = false
    state.error = action.payload
  },
})
