import history from '../history'
import qs from 'query-string'
import topicService from './topic.service'
import { fetchContentDocument, fetchContentDocumentByLegacyId } from '../services/download.service'
import { downloadFile } from '../utils/fileUtils'
import { enqueueSnackbar } from '../actions/notification.actions'
import store from '../store'
import { createDocumentQueryString } from '../helpers/createQueryStringObject'

const deepSyleStripper = el => {
  el.removeAttribute('style')
  el.removeAttribute('class')
  Array.from(el.children).forEach(child => {
    deepSyleStripper(child)
  })
  return el
}

const onClickSegmentLink = (topicId, segmentId) => {
  history.push(`/topic/${topicId}/segment/${segmentId}`)
}

const onClickTopicLink = topicId => {
  history.push(`/topic/${topicId}`)
}

const onClickDocumentLink = async (documentId, query, isLegacy)  => {
  try {
    const queryString = createDocumentQueryString(query)

    const documentData = isLegacy ? await fetchContentDocumentByLegacyId(documentId) : await fetchContentDocument(queryString)
    if (documentData?.fileContents) {
      downloadFile(documentData)
    } else {
      handleDownloadError(documentId, 'No file contents returned!')
    }
  } catch (error) {
    handleDownloadError(documentId, error)
  }
}

function handleDownloadError(documentId, err) {
  console.error(`Could not download documentID: ${documentId}. ${err}`)
  store.dispatch(
    enqueueSnackbar({
      message: `Could not download document.`,
      options: {
        variant: 'error',
      },
    })
  )
}

const removeAndAddAttribute = anchor =>  {
  anchor.removeAttribute('href')
  anchor.removeAttribute('target')
  anchor = deepSyleStripper(anchor)
  anchor.setAttribute('class', 'cimLink')
  return anchor
}

export const replaceCimLinks = async refCurrent => {
  if (!refCurrent) return
  const anchorTags = refCurrent.querySelectorAll('a')
  await anchorTags.forEach(async anchor => {

    const parsedUrl = qs.parseUrl(anchor.href.toString())
    const { query } = parsedUrl

    if (anchor.href.toLowerCase().includes('v1/contentmanagement/downloaddocument'))
      {
        removeAndAddAttribute(anchor)

        anchor.removeEventListener('click', () =>
          onClickDocumentLink(null , query, false)
        )
        anchor.addEventListener('click', () =>
          onClickDocumentLink(null , query, false)
        )

        return
      }

      // Legacy
      if (!anchor.href.toLowerCase().includes('default.aspx')) return

      // eslint-disable-next-line
      const anchorSectionId = query.s
      const legacyAnchorTopicId = query.t
      const legacyAnchorDocumentId = query.d
      const legacyAnchorSegmentId = query.ts
      const isCerberusContent = query.c
    let newAnchorTopicId = null
    if (legacyAnchorTopicId) {
      await topicService.getTopicIdFromLegacyId(parseInt(legacyAnchorTopicId))
    }

    let newAnchorSegmentId = null
    if (legacyAnchorSegmentId) {
      if (isCerberusContent) {
        newAnchorSegmentId = query.ts
      } else {
        newAnchorSegmentId = await topicService.getSegmentIdFromLegacyId(
          parseInt(legacyAnchorSegmentId)
        )
      }
    }
    removeAndAddAttribute(anchor)

    if (legacyAnchorDocumentId) {
      anchor.removeEventListener('click', () =>
        onClickDocumentLink(legacyAnchorDocumentId, query, true)
      )
      anchor.addEventListener('click', () =>
        onClickDocumentLink(legacyAnchorDocumentId, query, true)
      )
      return
    }
    if (legacyAnchorSegmentId && legacyAnchorTopicId) {
      anchor.removeEventListener('click', () =>
        onClickSegmentLink(legacyAnchorTopicId, legacyAnchorSegmentId)
      )
      anchor.addEventListener('click', () =>
        onClickSegmentLink(
          newAnchorTopicId ?? legacyAnchorTopicId,
          newAnchorSegmentId ?? legacyAnchorSegmentId
        )
      )
      return
    }
    if (legacyAnchorTopicId) {
      anchor.removeEventListener('click', () =>
        onClickTopicLink(legacyAnchorTopicId)
      )
      anchor.addEventListener('click', () =>
        onClickTopicLink(newAnchorTopicId ?? legacyAnchorTopicId)
      )
      return
    }
  })
}
