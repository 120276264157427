import { SET_IS_SEARCHING } from '../actions/types'

import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  isSearching: false,
}

export default createReducer(initialState, {
  [SET_IS_SEARCHING]: (state, action) => {
    state.isSearching = action.payload
  },
})
