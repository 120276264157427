// AUTH TYPES
export const STORE_USER = 'STORE_USER'
export const STORE_USER_ERROR = 'STORE_USER_ERROR'
export const USER_EXPIRING = 'USER_EXPIRING'
export const USER_EXPIRED = 'USER_EXPIRED'

export const SERVICE_ACCESS_REQUEST = 'SERVICE_ACCESS_REQUEST'
export const SERVICE_ACCESS_SUCCESS = 'SERVICE_ACCESS_SUCCESS'
export const SERVICE_ACCESS_FAILURE = 'SERVICE_ACCESS_FAILURE'

export const UPDATE_SESSION_EXPIRY_TIMESTAMP = 'UPDATE_SESSION_EXPIRY_TIMESTAMP'

// NOTIFICATION TYPES
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

// SECTION TYPES
export const SECTION_TOPIC_DATA_REQUEST = 'SECTION_TOPIC_DATA_REQUEST'
export const SECTION_TOPIC_DATA_SUCCESS = 'SECTION_TOPIC_DATA_SUCCESS'
export const SECTION_TOPIC_DATA_FAILURE = 'SECTION_TOPIC_DATA_FAILURE'

// BRANDING TYPES
export const GET_BRANDING_REQUEST = 'GET_BRANDING_REQUEST'
export const GET_BRANDING_SUCCESS = 'GET_BRANDING_SUCCESS'
export const GET_BRANDING_FAILURE = 'GET_BRANDING_FAILURE'

// APP CONFIG TYPES
export const HIDE_ACCESS_LEVEL_PROMPT = 'HIDE_ACCESS_LEVEL_PROMPT'
export const SHOW_ACCESS_LEVEL_PROMPT = 'SHOW_ACCESS_LEVEL_PROMPT'
export const GET_ACCESS_LEVEL_REQUEST = 'GET_ACCESS_LEVEL_REQUEST'
export const GET_ACCESS_LEVEL_SUCCESS = 'GET_ACCESS_LEVEL_SUCCESS'
export const GET_ACCESS_LEVEL_FAILURE = 'GET_ACCESS_LEVEL_FAILURE'

// USER AVAILABILITY
export const USER_AVAILABILITY_REQUEST = 'USER_AVAILABILITY_REQUEST'
export const USER_AVAILABILITY_SUCCESS = 'USER_AVAILABILITY_SUCCESS'
export const USER_AVAILABILITY_FAILURE = 'USER_AVAILABILITY_FAILURE'

//ACCESS LEVEL TYPES
export const GET_HR_ACCESS_LEVELS_REQUEST = 'GET_HR_ACCESS_LEVELS_REQUEST'
export const GET_HR_ACCESS_LEVELS_SUCCESS = 'GET_HR_ACCESS_LEVELS_SUCCESS'
export const GET_HR_ACCESS_LEVELS_FAILURE = 'GET_HR_ACCESS_LEVELS_FAILURE'

//SERVICE APP LABEL
export const SERVICE_LABEL_DATA_REQUEST = 'SERVICE_LABEL_DATA_REQUEST'
export const SERVICE_LABEL_DATA_SUCCESS = 'SERVICE_LABEL_DATA_SUCCESS'
export const SERVICE_LABEL_DATA_FAILURE = 'SERVICE_LABEL_DATA_FAILURE'

//SEARCH
export const SET_IS_SEARCHING = 'SET_IS_SEARCHING'

//APPLICATION_SERVICE_GET_SUCCESS
export const APPLICATION_SERVICE_GET_SUCCESS = 'APPLICATION_SERVICE_GET_SUCCESS'
export const APPLICATION_SERVICE_GET_REQUEST = 'APPLICATION_SERVICE_GET_REQUEST'
export const APPLICATION_SERVICE_GET_FAILURE = 'APPLICATION_SERVICE_GET_FAILURE'
