import { serviceConstants } from '../constants/service.constants'
import { createReducer } from '@reduxjs/toolkit'

const initialState = {}

export const serviceReducer = createReducer(initialState, {
  [serviceConstants.GET_SUCCESS]: (state, action) => {
    return action.payload
  },
})
