export default {
  ADMIN_APP: 1,
  KNOWLEDGE_PORTAL_APP: 2,
  MI_PORTAL_APP: 3,
  PROCESS_ENGINE_APP: 4,
  DASHBOARD_APP: 5,
  CASE_NOTE_WIZARD_APP: 6,
  DOC_SERVICE_APP: 7,
  USER_PROFILE_APP: 8,
}
