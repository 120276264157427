import React, { useState, useEffect } from 'react'

import {
  makeStyles,
  Box,
  ListItemText,
  List,
  ListItem,
} from '@material-ui/core/'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import otherSectionTypes from '../constants/otherSectionTypes.constants'

const useStyles = makeStyles(() => {
  return {
    listItem: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    listItemSelected: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  }
})

const TopicMenu = ({ topicSelected, sectionTopics }) => {
  const classes = useStyles()

  const [selectedSection, setSelectedSection] = useState(null)
  const [column1TopicList, setColumn1TopicList] = useState([])
  const [column2TopicList, setColumn2TopicList] = useState([])
  const [sortedSectionTopic, setSortedSectionTopic] = useState(sectionTopics)

  useEffect(() => {
    setSelectedSection(null)
  }, [sectionTopics])

  useEffect(() => {
    if (!selectedSection) return
    const selectedSectionItems = selectedSection.isOtherSection
      ? selectedSection.otherSections.filter(
          topic => topic.otherSectionType !== otherSectionTypes.HOMEPAGE
        )
      : selectedSection.topics
    const sectionCount = selectedSectionItems.length
    const column1TopicListCount = Math.ceil(sectionCount / 2)
    let column1Topics = []
    let column2Topics = []
    selectedSectionItems.forEach((topic, index) => {
      if (index < column1TopicListCount) column1Topics.push(topic)
      else column2Topics.push(topic)
    })
    setColumn1TopicList(column1Topics)
    setColumn2TopicList(column2Topics)
  }, [selectedSection])

  useEffect(() => {
    var sortedSectionTopics = sortedSectionTopic.slice().sort(function(a, b) {
      if (a.name === 'Other' || b.name === 'Other') return 1
      return a.orderBy - b.orderBy;
    })
    
    setSortedSectionTopic(sortedSectionTopics)
  }, [])

  const selectTopic = (topic, selectedSection) => {
    topicSelected(topic, selectedSection)
  }

  return (
    <Box style={{ display: 'flex' }}>
      <List
        style={{
          width: 300,
          borderRight: selectedSection ? '1px solid #95a5a6' : 'none',
        }}>
        {sortedSectionTopic.map(section => {
          if (section.isOtherSection === true && section.otherSections.every(x => x.otherSectionType === 1)) {
            return 
          }
          return (
          <ListItem
            key={section.id}
            button
            onClick={() => setSelectedSection(section)}
            className={
              selectedSection && selectedSection.id === section.id
                ? classes.listItemSelected
                : classes.listItem
            }>
            <ListItemText primary={section.name} />
            {selectedSection && selectedSection.id === section.id ? (
              <ChevronRightIcon />
            ) : (
              <ChevronRightIcon style={{ visibility: 'hidden' }} />
            )}
          </ListItem>
        )}
        )}
      </List>
      {selectedSection && (
        <Box style={{ display: 'block', width: 600 }}>
          <h3 style={{ paddingLeft: 16, marginTop: 16, marginBottom: 16 }}>
            {selectedSection.name}
          </h3>
          <Box style={{ display: 'flex' }}>
            {
              <List style={{ width: 300 }}>
                {column1TopicList
                  .filter(
                    topic =>
                      topic.otherSectionType !== otherSectionTypes.HOMEPAGE
                  )
                  .map(topic => (
                    <ListItem
                      key={topic.id}
                      button
                      onClick={() =>
                        selectTopic(topic, selectedSection)
                      }>
                      <ListItemText primary={topic.name} />
                    </ListItem>
                  ))}
              </List>
            }
            {
              <List style={{ width: 300 }}>
                {column2TopicList
                  .filter(
                    topic =>
                      topic.otherSectionType !== otherSectionTypes.HOMEPAGE
                  )
                  .map(topic => (
                    <ListItem
                      key={topic.id}
                      button
                      onClick={() =>
                        selectTopic(topic, selectedSection)
                      }>
                      <ListItemText primary={topic.name} />
                    </ListItem>
                  ))}
              </List>
            }
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default TopicMenu
