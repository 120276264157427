import React, { useEffect, useRef } from 'react'
import { Paper } from '@material-ui/core'
import { replaceCimLinks } from '../services/cim.service'

const TopicFlatContent = ({ topic }) => {

  const segmentHtmlRef = useRef(null)

  useEffect(() => {
    if (topic) {
      replaceCimLinks(segmentHtmlRef.current)
    }
  }, [topic])

  return (
    <>
      {topic && (
        <Paper style={{ padding: 24 }}>
          <div
            ref={segmentHtmlRef}
            dangerouslySetInnerHTML={{ __html: topic.contentHtml }}
          />
        </Paper>
      )}
    </>
  )
}

export default TopicFlatContent