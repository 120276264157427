import httpClient from '../utils/axios'
import {
  handleServiceError,
  handleServiceResponse,
} from '../utils/serviceResponseHelper'

const searchContentAsync = async encodedSearchTerm => {
  return httpClient
    .get(
      `${window.config.KNOWLEDGEPORTAL_API_URL}/v1/ContentManagement/SearchContent/${encodedSearchTerm}`
    )
    .then(response => {
      return handleServiceResponse(response)
    })
    .catch(error => handleServiceError(error))
}
export default {
  searchContentAsync,
}
