import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '../actions/types'
import { createReducer } from '@reduxjs/toolkit'

const initialState = []

export default createReducer(initialState, {
  [ENQUEUE_SNACKBAR]: (state, action) => {
    state.push({
      key: action.key,
      ...action.notification,
    })
  },
  [CLOSE_SNACKBAR]: (state, action) => {
    return state.map(notification =>
      action.dismissAll || notification.key === action.key
        ? { ...notification, dismissed: true }
        : { ...notification }
    )
  },
  [REMOVE_SNACKBAR]: (state, action) => {
    return state.filter(notification => notification.key !== action.key)
  },
})
