import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Button, Paper, Box } from '@material-ui/core/'
import TopicDocumentList from '../components/topicDocumentList'
import { makeStyles } from '@material-ui/core/styles'
import MainLayout from '../layouts/mainLayout'
import TopicFlatContent from '../components/topicFlatContent'
import TopicDynamicWorkflow from '../components/topicDynamicWorkflow'
import qs from 'query-string'
import topicService from '../services/topic.service'
import LoadingSpinner from '../components/loadingSpinner'
import sectionReducer from '../reducers/section.reducer'

const useStyles = makeStyles(theme => ({
  root: {
    width: '70%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing(1),
  },
  spinnerContainer: {
    marginTop: '6%',
  },
}))

const Topic = () => {
  const parsedUrl = qs.parseUrl(window.location.href)
  const { query } = parsedUrl

  const classes = useStyles()

  const { topicId } = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const segmentIdFromQuery = parseInt(searchParams.get('segment'))
  const segmentName = searchParams.get('segmentName')
  const accesslevelIdFromQuery = searchParams.get('accesslevel')
  const topicIdFromQuery = parseInt(searchParams.get('topic'))

  const stateSections = useSelector(state => state.section.sectionTopics) ?? []

  const [topic, setTopic] = useState(null)
  const [displayPolicyPage, setDisplayPolicyPage] = useState(true)
  const [serviceQueryString, setServiceQueryString] = useState()
  const [sectionAndTopicDetails, setSectionAndTopicDetails] = useState()
  const [onError, setOnError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const createSectionAndTopicQueryString = () => {
    //Find correct section from topicId url param
    let found = false
    stateSections.map(section => {
      return section.topics?.map(topic => {
        const currentTopicId = topicId === undefined ? topicIdFromQuery : parseInt(topicId)


        if (topic.id === currentTopicId ) {
          found = true
          const data = {
            topicId:  currentTopicId,
            topicName: topic.name,
            sectionId: section.id,
            sectionName: section.name,
          }
          setServiceQueryString(
            Object.keys(data)
              .map(key => key + '=' + data[key])
              .join('&')
          )
          setSectionAndTopicDetails(data)
        }
      })
    })
    if (!found) {
      // Used to handle the legacy / CIM Ids embedded in the contentHtml
      setServiceQueryString('topicId=' + topicId)
    }
  }

  useEffect(() => {
    createSectionAndTopicQueryString()
  }, [topicId, stateSections])

  useEffect(() => {
    setOnError(false)
    if (serviceQueryString) {
      setIsLoading(true)
      topicService
        .getTopicAsync(serviceQueryString)
        .then(response => {
          setTopic(response)
          setIsLoading(false)
        })
        .catch(error => {
          console.log(error)
          setOnError(true)
          setTopic(null)
          setIsLoading(false)
        })
    }
  }, [serviceQueryString])

  return (
    <MainLayout>
      {topic && !isLoading && (
        <>
          <h1>{topic.name}</h1>
          <Button
            className={classes.button}
            variant={displayPolicyPage ? 'contained' : 'outlined'}
            color='primary'
            onClick={() => setDisplayPolicyPage(true)}>
            Policy
          </Button>
          <Button
            className={classes.button}
            variant={displayPolicyPage ? 'outlined' : 'contained'}
            color='primary'
            onClick={() => setDisplayPolicyPage(false)}>
            Policy Documents
          </Button>
          {displayPolicyPage ? (
            topic.isTopicDiagramEnabled ? (
              <TopicDynamicWorkflow
                topic={topic}
                segmentId={segmentIdFromQuery}
                sectionAndTopicDetails={sectionAndTopicDetails}
                segmentName={segmentName}
                accesslevelId={accesslevelIdFromQuery}
              />
            ) : (
              <TopicFlatContent topic={topic} />
            )
          ) : (
            <TopicDocumentList
              documents={topic.documents}
              sectionId={topic.sectionId}
              topicId={topic.id}
              sectionAndTopicDetails={sectionAndTopicDetails}
            />
          )}
        </>
      )}
      {onError && (
        <Paper style={{ padding: 24 }}>
          <p>Error, Could not load topic...</p>
        </Paper>
      )}
      {isLoading && (
        <Box className={classes.spinnerContainer}>
          <LoadingSpinner text={'Loading topic'} />
        </Box>
      )}
    </MainLayout>
  )
}

export default Topic
