const Images = {
  docIcon: require('../images/docicons/doc-small.gif'),
  docxIcon: require('../images/docicons/docx-small.gif'),
  htmlIcon: require('../images/docicons/htm-small.gif'),
  jpgIcon: require('../images/docicons/jpg-small.gif'),
  msgIcon: require('../images/docicons/msg-small.gif'),
  pdfIcon: require('../images/docicons/pdf-small.gif'),
  pngIcon: require('../images/docicons/png-small.gif'),
  pptIcon: require('../images/docicons/ppt-small.gif'),
  pptxIcon: require('../images/docicons/pptx-small.gif'),
  pubIcon: require('../images/docicons/pub-small.gif'),
  pubxIcon: require('../images/docicons/pubx-small.gif'),
  rtfIcon: require('../images/docicons/rtf-small.gif'),
  tifIcon: require('../images/docicons/tif-small.gif'),
  txtIcon: require('../images/docicons/txt-small.gif'),
  xlsIcon: require('../images/docicons/xls-small.gif'),
  xlsxIcon: require('../images/docicons/xlsx-small.gif'),
  unknownIcon: require('../images/docicons/xxx-small.gif'),
  zipIcon: require('../images/docicons/zip-small.gif'),
}

function GetIcon(extension) {
  switch (extension) {
    case '.doc':
      return Images.docIcon
    case '.docx':
      return Images.docxIcon
    case '.htm':
      return Images.htmIcon
    case '.html':
      return Images.htmIcon
    case '.jpg':
      return Images.jpgIcon
    case '.msg':
      return Images.msgIcon
    case '.pdf':
      return Images.pdfIcon
    case '.ppt':
      return Images.pptIcon
    case '.pptx':
      return Images.pptxIcon
    case '.pub':
      return Images.pubIcon
    case '.pubx':
      return Images.pubxIcon
    case '.rtf':
      return Images.rtfIcon
    case '.tif':
      return Images.tifIcon
    case '.txt':
      return Images.txtIcon
    case '.xls':
      return Images.xlsIcon
    case '.xlsx':
      return Images.xlsxIcon
    case '.xlsm':
      return Images.xlsxIcon
    case '.zip':
      return Images.zipIcon
    default:
      return Images.unknownIcon
  }
}

export default GetIcon
