import {
  SERVICE_LABEL_DATA_REQUEST,
  SERVICE_LABEL_DATA_SUCCESS,
  SERVICE_LABEL_DATA_FAILURE,
} from '../actions/types'

import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  serviceLabelData: null,
  isLoading: false,
  error: null,
}

export default createReducer(initialState, {
  [SERVICE_LABEL_DATA_REQUEST]: state => {
    state.isLoading = true
  },
  [SERVICE_LABEL_DATA_SUCCESS]: (state, action) => {
    state.serviceLabelData = action.payload //JSON.parse(action.payload)
    state.isLoading = false
    state.error = null
  },
  [SERVICE_LABEL_DATA_FAILURE]: (state, action) => {
    state.isLoading = false
    state.error = action.payload
  },
})
