import React, { useEffect, useState } from 'react'
import MainLayout from '../layouts/mainLayout'
import sectionService from '../services/section.service'
import { useDispatch } from 'react-redux'
import { enqueueSnackbar } from '../actions/notification.actions'
import LoadingSpinner from '../components/loadingSpinner'

const Home = () => {
  const dispatch = useDispatch()
  const [homepage, setHomepage] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const getHomePageData = () => {
    try {
      setIsLoading(true)
      sectionService.getOtherSectionById(0).then(res => setHomepage(res))
    } catch (error) {
      console.error(error)
      dispatch(
        enqueueSnackbar({
          message: `Could not fetch content.`,
          options: {
            variant: 'error',
          },
        })
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getHomePageData()
  }, [])

  return (
    <>
      <MainLayout>
        {isLoading ? (
          <LoadingSpinner text='Loading content' /> 
        ) : (
          <>
            <h1>{homepage?.name}</h1>
            <div dangerouslySetInnerHTML={{ __html: homepage?.contentHtml }}></div>
          </>
        )}
      </MainLayout>
    </>
  )
}

export default Home
