import {
  GET_BRANDING_FAILURE,
  GET_BRANDING_SUCCESS,
  GET_BRANDING_REQUEST,
  APPLICATION_SERVICE_GET_SUCCESS,
  APPLICATION_SERVICE_GET_REQUEST,
  APPLICATION_SERVICE_GET_FAILURE,
} from './types'
import brandingService from '../services/branding.service'

export function getServiceBranding() {
  return async dispatch => {
    dispatch(serviceBrandingRequest())
    try {
      const serviceBranding = await brandingService.getServiceBranding()
      dispatch(serviceBrandingSuccess(serviceBranding || null))
    } catch (error) {
      dispatch(serviceBrandingFailure())
    }
  }
  function serviceBrandingRequest() {
    return {
      type: GET_BRANDING_REQUEST,
    }
  }

  function serviceBrandingSuccess(response) {
    return {
      type: GET_BRANDING_SUCCESS,
      payload: response,
    }
  }

  function serviceBrandingFailure() {
    return {
      type: GET_BRANDING_FAILURE,
    }
  }
}

export function getServiceApplications() {
  return dispatch => {
    dispatch(request())

    return new Promise(function (resolve, reject) {
      brandingService.getServiceApplications().then(
        response => {
          dispatch(success(response))
          return resolve(response)
        },
        error => {
          dispatch(failure(error))
          return reject(error)
        }
      )
    })
  }

  function request() {
    return { type: APPLICATION_SERVICE_GET_REQUEST }
  }
  function success(response) {
    return {
      type: APPLICATION_SERVICE_GET_SUCCESS,
      response,
    }
  }
  function failure(error) {
    return {
      type: APPLICATION_SERVICE_GET_FAILURE,
      error,
    }
  }
}
