import {
  USER_AVAILABILITY_REQUEST,
  USER_AVAILABILITY_SUCCESS,
  USER_AVAILABILITY_FAILURE,
} from './types'

import userService from '../services/user.service'

function userAvailabilityRequest() {
  return {
    type: USER_AVAILABILITY_REQUEST,
  }
}

function userAvailabilitySuccess(response) {
  return {
    type: USER_AVAILABILITY_SUCCESS,
    payload: response,
  }
}

function userAvailabilityFailure(error) {
  return {
    type: USER_AVAILABILITY_FAILURE,
    payload: error,
  }
}

export function setUserAvailabilityAsync(availability) {
  return async dispatch => {
    dispatch(userAvailabilityRequest())
    try {
      await userService.setUserAvailabilityAsync(availability)
      return dispatch(userAvailabilitySuccess(availability))
    } catch (error) {
      return dispatch(userAvailabilityFailure(error))
    }
  }
}

export function getUserAvailabilityAsync() {
  return async dispatch => {
    dispatch(userAvailabilityRequest())
    try {
      const response = await userService.getUserAvailabilityAsync()
      return dispatch(userAvailabilitySuccess(response))
    } catch (error) {
      return dispatch(userAvailabilityFailure(error))
    }
  }
}
