import {
  SECTION_TOPIC_DATA_REQUEST,
  SECTION_TOPIC_DATA_SUCCESS,
  SECTION_TOPIC_DATA_FAILURE,
} from './types'

import topicService from '../services/topic.service'

function sectionTopicRequest() {
  return {
    type: SECTION_TOPIC_DATA_REQUEST,
  }
}

function sectionTopicSuccess(response) {
  return {
    type: SECTION_TOPIC_DATA_SUCCESS,
    payload: response,
  }
}

function sectionTopicFailure(error) {
  return {
    type: SECTION_TOPIC_DATA_FAILURE,
    payload: error,
  }
}

export const fetchSectionTopicData = () => {
  return async dispatch => {
    dispatch(sectionTopicRequest())
    try {
      const response = await topicService.getSectionsAndTopicsAsync()
      return dispatch(sectionTopicSuccess(response))
    } catch (error) {
      return dispatch(sectionTopicFailure(error))
    }
  }
}
