import React, { useEffect } from 'react'

import { Router, Switch, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import NotificationHandler from './components/notificationHandler'
import NoPermissions from './pages/noPermissions'
import NoAccess from './pages/noAccess'
import Oops from './pages/oops'
import Home from './pages/home'
import Topic from './pages/topic'
import SignInCallback from './callbacks/signInCallback'
import SignOutCallback from './callbacks/signOutCallback'
import SignInSilentCallback from './callbacks/signInSilentCallback'
import AuthenticationProvider from './providers/authenticationProvider'
import userService from './services/auth.service'
import ProtectedRoute from './components/routing/protectedRoute'
import store from './store'
import history from './history'
import { useSelector, useDispatch } from 'react-redux'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import InactivityMonitor from './components/inactivityMonitor'
import Search from './pages/search'
import OtherSection from './pages/otherSection'
import * as actions from './actions/service.actions'
import GnattaChatComponent from './components/webChat/GnattaChatComponent'

function App() {
  const dispatch = useDispatch()

  const { theme, defaultTheme } = useSelector(state => state.branding)
  const muiTheme = createMuiTheme(theme || defaultTheme)

  const userServiceId = useSelector(state => state.auth.profile['service_id'])

  useEffect(() => {
    if (userServiceId) {
      dispatch(actions.serviceActions.get(userServiceId))
    }
  }, [userServiceId, dispatch])

  return (
    <ThemeProvider theme={{ ...muiTheme }}>
      <GnattaChatComponent />
      <AuthenticationProvider userService={userService} store={store}>
        <SnackbarProvider>
          <NotificationHandler />
          <Router history={history}>
            <InactivityMonitor />
            <Switch>
              <ProtectedRoute exact path='/' component={Home} />
              <ProtectedRoute
                exact
                path='/topic/:topicId/segment/:segmentId'
                component={Topic}
              />
              <ProtectedRoute exact path='/topic/:topicId' component={Topic} />
              <ProtectedRoute exact path='/topic/' component={Topic} />
              <ProtectedRoute
                path='/other/:otherSectionId'
                component={OtherSection}
              />
              <ProtectedRoute
                path='/search/:encodedUriSearchTerm?'
                component={Search}
              />
              <Route path='/signin-oidc' component={SignInCallback} />
              <Route path='/signout-oidc' component={SignOutCallback} />
              <Route path='/silent-oidc' component={SignInSilentCallback} />
              <Route path='/permissions' component={NoPermissions} />
              <Route path='/access' component={NoAccess} />
              <Route path='*' component={Oops} />
            </Switch>
          </Router>
        </SnackbarProvider>
      </AuthenticationProvider>
    </ThemeProvider>
  )
}

export default App
